<template>
  <a-config-provider :locale="localeRef" :autoInsertSpaceInButton="false">
    <a-layout
      id="components-layout"
      class="ant-layout ant-layout-has-sider boxS"
    >
      <Sider
        :navs="currentRoutes"
        :collapsed="collapsed"
        @toggle-collapsed="toggleCollapsed"
      />
      <!--      <Sider/>-->
      <a-layout class="ant-layout" style="position: relative">
        <Header
          :collapsed="collapsed"
          @toggle-collapsed="toggleCollapsed"
          @showPage="showPage"
        />
        <a-layout-content id="admin-home-content" class="boxS">
          <!--          <router-view/>-->
          <router-view v-slot="{ Component }">
            <!-- <keep-alive :include="$store.state.keepAliveRoute"> -->
            <keep-alive :include="keepAliveInclude">
              <component class="view" :is="Component" />
            </keep-alive>
          </router-view>
          <!-- <w_home_button></w_home_button> -->
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- 系统设置模块 -->
    <settCpn ref="settRef"></settCpn>
  </a-config-provider>
</template>
<script>
import moment from 'moment';
import settCpn from '@/components/systemSetting/systCpn.vue';
import Sider from '@/components/sider_/index';
import Header from '@/components/Header';
import en_US from 'ant-design-vue/es/locale/en_US';
import zh_CN from 'ant-design-vue/es/locale/zh_CN';
import 'moment/locale/zh-cn';
// import { asyncRoutes } from '@/router/index';
import { onMounted, reactive, toRefs, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  components: {
    Sider,
    Header,
    settCpn,
  },
  setup() {
    const settRef = ref();
    let { t, locale } = useI18n();
    const store = useStore();
    let data = reactive({
      collapsed: false,
      navs: [],
    });
    const localeRef = ref();
    const getAction = () => {
      const payload = localStorage.getItem('languageSet');
      if (payload) {
        moment.locale(payload);
        if (payload == 'zh_CN') {
          localeRef.value = zh_CN;
        } else {
          localeRef.value = en_US;
        }
      } else {
        moment.locale('zh-cn');
        localeRef.value = zh_CN;
      }
    };
    getAction();
    watch(locale, () => {
      getAction();
    });
    onMounted(() => {
      store.state.homeWidth =
        document.getElementById('admin-home-content').offsetWidth;
      store.state.homeHeight =
        document.getElementById('admin-home-content').offsetHeight;
      window.addEventListener('resize', function () {
        store.state.homeWidth =
          document.getElementById('admin-home-content').offsetWidth;
        store.state.homeHeight =
          document.getElementById('admin-home-content').offsetHeight;
      });
    });
    function toggleCollapsed(flag) {
      data.collapsed = flag;
    }
    const showPage = () => {
      settRef.value.action();
    };
    const currentRoutes = computed(() => store.getters.currentRoutes)
    console.log('currentRoutes :>> ', currentRoutes);
    const keepAliveInclude = computed(() => store.getters.keepAliveInclude)
    console.log('keepAliveInclude :>> ', keepAliveInclude);
    return {
      settRef,
      localeRef,
      toggleCollapsed,
      ...toRefs(data),
      // asyncRoutes,
      currentRoutes,
      keepAliveInclude,
      showPage,
      t,
    };
  },
};
</script>
<style>
.ant-drawer-content-wrapper {
  width: 370px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}
</style>
<style lang="less">
::v-deep(.ant-form-item-control-input-content) {
  height: 32px !important;
}
.mesInput {
  position: absolute;
  top: -20px;
  left: 2px;
  color: #131011;
  font-size: 12px;
  line-height: 18px;
}
.quitMess {
  color: #050505;
  font-size: 16px;
  line-height: 22px;
  margin: 24px 0;
}
.quitBtn {
  margin-top: 0 !important;
}

.inputIcon {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 18px;
  margin-right: 6px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.inputIconSenc {
  // top: 30% !important;
}

.eyeBox {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  font-size: 14px;
}
.eyeBoxSenc {
  // top: 30%;
}
.inputMes {
  color: #131011;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
}
.btnBoxs {
  margin-top: 32px;
  width: 305px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  .btns {
    width: 144px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }
  .leftBtn {
    background: #d2d2d2;
    color: #131011;
  }
  .rightBtn {
    background: #ff6700;
    color: #fff;
  }
}
.navDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tit {
    color: #131011;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }
  .colse {
    font-size: 14px;
    color: #131011;
    cursor: pointer;
  }
}
.btns {
  width: 305px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}
.btn1 {
  background: #ff6700;
}
.btn2 {
  background: #131011;
  margin-top: 40px;
}
.divItem {
  margin-top: 30px;
  .title {
    color: #131011;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
  }
}
#components-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  #admin-home-content {
    position: relative;
    overflow-y: scroll;
    //overflow-x:scroll;
    //padding-top: 20px;
    //padding-bottom: 20px;
    //padding: 20px;
  }

  .trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    font-size: 20px;
    color: white;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /*background: #001529;*/
    z-index: 1;

    img {
      height: 16px;
    }
  }
}

.ant-layout {
  //background-color: #F7F7FF;
  background-color: #ffffff;
}
</style>
