<template>
  <a-layout-header class="header flexS boxS">
    <div class="flexL">
      <Breadcrumb></Breadcrumb>
    </div>
    <div class="flexR">
      <div class="userInfoView flexR">
        {{
          $store.getters.userInfo.businessBo &&
          $store.getters.userInfo.businessBo.name
        }}
        - {{ $store.getters.userInfo && $store.getters.userInfo.account }}
        <a-avatar>{{
          $store.getters.userInfo.businessBo &&
          $store.getters.userInfo.businessBo.name.split('')[0]
        }}</a-avatar>
        <div class="settImg" @click="showSettingPage">
          <img src="@/assets/images/login/settingPic.png" alt="" />
        </div>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import Breadcrumb from './Breadcrumb';
export default {
  name: 'Header',
  components: {
    Breadcrumb,
  },
  props: {
    collapsed: Boolean,
  },
  setup(props, { emit }) {
    const showSettingPage = () => {
      emit('showPage');
    };
    return { showSettingPage };
  },
};
</script>

<style lang="less" scoped>
.settImg {
  margin-left: 16px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
    display: flex;
  }
}
.header {
  background: #fff;
  z-index: 10;
  // border-bottom: 1px solid rgb(228, 228, 228 , 0%);
  //box-shadow: 0 2px 3px 0 rgb(218, 218, 253, 65%), 0 0px 6px 0 rgb(206, 206, 238, 54%);
  padding: 0 32px !important;
}
.userInfoView {
  min-width: 150px;
  height: 40px;
  color: #131011;
  font-size: 14px;
  line-height: 40px;
  ::v-deep(.ant-avatar) {
    background: #eceff1;
    width: 40px;
    height: 40px;
    margin-left: 20px;
    color: black;
    line-height: 40px;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
