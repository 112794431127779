<template>
  <a-breadcrumb class="flexL breadcrumb">
    <template #separator
      ><span
        style="
          font-weight: bold;
          font-size: 20px;
          color: #5d5758;
          font-family: monospace;
        "
        >·</span
      ></template
    >
    <template v-if="levelList.length != 0">
      <a-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <a :path="item.path" @click="$router.push(item.path)" v-if="!index">
          {{
            t(
              returnName(item, index, levelList) +
                (item.children.length ? `.${item.name}` : '')
            )
          }}
        </a>
        <a :path="item.path" v-else>
          {{
            t(
              returnName(item, index, levelList) +
                (item.children.length ? `.${item.name}` : '')
            )
          }}
        </a>
      </a-breadcrumb-item>
    </template>
  </a-breadcrumb>
</template>

<script>
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export default {
  name: 'Breadcrumb',
  data() {
    return {};
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    let levelList = computed(() => {
      let levelList = route.matched.filter((item) => item.name);
      console.log(levelList, 'levelList---levelList');
      return levelList;
    });
    const returnName = (item, index, arr) => {
      let name = 'route';
      arr.forEach((data, dataIndex) => {
        if (dataIndex <= index) {
          if (data.children && data.children.length > 0) {
            name += `.${data.name}`;
          } else {
            name += `.${data.name}`;
          }
        }
      });
      return name;
    };
    return {
      t,
      levelList,
      returnName,
    };
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  padding: 10px 0px;
  min-width: 200px;
}
.ant-breadcrumb span:last-child a {
  color: #ff6700;
}
.ant-breadcrumb span {
  height: 22px;
  display: flex;
  align-items: center;
}
.ant-breadcrumb a:hover {
  color: #ff6700;
}
</style>
