<template>
  <div class="sider" :class="collapsed ? 'width_64' : ' width_250'">
    <div class="logoView flexS boxS" :class="collapsed ? 'minLogoView' : ''">
      <p class="flexL" v-if="!collapsed">
        <img
          style="width: 80px; height: 26px; display: flex"
          src="@/assets/images/logoIcon.png"
          alt=""
        />
        <i>{{ t('logoName') }}</i>
      </p>
      <div class="iconsBox" @click="collClick">
        <RightOutlined v-if="collapsed" />
        <LeftOutlined v-else />
      </div>
    </div>
    <a-menu
      theme="dark"
      mode="inline"
      :inline-collapsed="collapsed"
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
    >
      <template v-for="item in navs">
        <sub-menu
          v-if="item.children && item.hidden"
          :menu-info="item"
          :key="item.path"
          :collapsed="collapsed"
          :name="item.name"
          :path="item.path"
        />
      </template>
    </a-menu>
  </div>
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
import SubMenu from './SubMenu';

export default {
  name: 'index',
  props: {
    navs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    SubMenu,
    LeftOutlined,
    RightOutlined,
  },
  setup(props) {
    const { t } = useI18n();
    const collapsed = ref(false);
    // let router = useRouter();
    let route = useRoute();
    let data = reactive({
      openKeys: [],
      selectedKeys: [],
      levelList: null,
    });
    watch(
      () => route.matched,
      () => {
        data.levelList = route.matched;
        push();
      }
    );
    onMounted(() => {
      data.levelList = route.matched;
      push();
    });
    //初始化默认展开全部
    const actionFun = (list) => {
      list.map((item) => {
        if (item.children && item.children.length > 0) {
          data.openKeys.push(item.path);
          actionFun(item.children);
        }
      });
    };
    const push = () => {
      let selectedKeys = [];
      data.levelList.forEach((item, index) => {
        if (index === data.levelList.length - 1) {
          selectedKeys.push(item.path);
          return false;
        }
      });
      data.selectedKeys = selectedKeys;
    };
    actionFun(props.navs);
    //控制菜单栏的展开收起
    const collClick = () => {
      collapsed.value = !collapsed.value;
    };
    // let pathArr = route.path.split('/');
    // if (pathArr.length > 3) {
    //   data.openKeys = [`/${pathArr[1]}/${pathArr[2]}`];
    //   data.selectedKeys = [`/${pathArr[1]}/${pathArr[2]}/${pathArr[3]}`];
    // } else {
    //   data.selectedKeys = [`/${pathArr[1]}/${pathArr[2]}`];
    // }
    // function addOpenKeys(path) {
    //   if (data.openKeys.indexOf(path) !== -1) {
    //     data.openKeys.splice(data.openKeys.indexOf(path), 1);
    //   } else {
    //     data.openKeys = [];
    //     data.openKeys.push(path);
    //   }
    // }
    // function addSelectedKeys(path, close) {
    //   console.log(path);
    //   if (data.selectedKeys.indexOf(path) !== -1) return false;
    //   data.selectedKeys = [path];
    //   router.push(path);
    //   if (close) {
    //     data.openKeys = [];
    //   }
    // }
    return {
      collapsed,
      ...toRefs(data),
      // addOpenKeys,
      // addSelectedKeys,
      t,
      collClick,
    };
  },
};
</script>
<style>
.ant-menu {
  background: #131011 !important;
}
.ant-menu-submenu {
  background: #131011 !important;
}
.ant-menu-submenu-title {
  padding-left: 24px !important;
}
.ant-menu-item-only-child {
  padding-left: 24px !important;
}
.ant-menu-item-selected {
  background: #5d5758 !important;
  border-radius: 6px;
}
</style>
<style scoped lang="less">
::v-deep(.ant-menu-submenu-title) {
  display: flex;
  justify-content: center;
}
p {
  margin: 0 !important;
  padding: 0 !important;
}
.margin_0 {
  margin: 12px !important;
}
.width_64 {
  width: 80px;
}
.width_250 {
  width: 250px;
}
.iconsBox {
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
  background: #403a3c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  span {
    margin: 0 !important;
    width: 14px !important;
    height: 14px !important;
    font-size: 12px !important;
  }
}

.sider {
  height: 100%;
  background: #131011;
  z-index: 20;
  position: relative;
  transition: all ease 0.15s;
  border-right: 1px solid rgb(228, 228, 228, 0%);
  .logoView {
    width: 100% !important;
    height: 64px !important;
    span {
      width: 40px;
      height: 40px;
      color: #fff;
      font-size: 18px;
      margin-right: 15px;
      transition: all 0.3s ease-out;
      border-radius: 2px;
    }
    span:hover {
      background: rgba(13, 110, 253, 0.12);
      cursor: pointer;
    }
    p {
      height: 64px !important;
      font-size: 20px !important;
      font-weight: bold;
      color: #fff;
      margin-left: 15px !important;
      position: relative;
      i {
        font-size: 13px !important;
        margin-top: 2px;
      }
    }
  }
  .minLogoView {
    display: flex;
    justify-content: center;
  }
}
</style>
