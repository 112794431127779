<template>
  <div>
    <a-drawer
      v-model:visible="visible"
      class="custom-class"
      style="color: red"
      placement="right"
      :closable="false"
      @close="closeDiv"
    >
      <div class="navDiv">
        <div class="tit">
          {{
            quitRef
              ? t('setting.quitLogin')
              : typeFlag
              ? t('setting.crmSetting')
              : t('setting.updatePassword')
          }}
        </div>
        <div class="colse" @click="closeClick"><close-outlined /></div>
      </div>
      <template v-if="typeFlag && !quitRef">
        <div class="divItem">
          <div class="title">{{ t('setting.password') }}</div>
          <div class="btns btn1" @click="updatePassword">
            {{ t('setting.updatePassword') }}
          </div>
        </div>
        <div class="divItem" v-if="$route.path != '/transfer' && userInfo.platformNames && userInfo.platformNames.length > 1">
          <div class="title">{{ t('setting.platform') }}</div>
          <div style="width: 305px; height: 40px; margin-bottom: 10px">
            <a-select
              ref="select"
              v-model:value="userType"
              style="width: 305px"
              size="large"
            >
              <a-select-option :value="platformNames.Amazon">{{ t('setting.amazon') }}</a-select-option>
              <a-select-option :value="platformNames.Wahool">{{ t('setting.wahool') }}</a-select-option>
            </a-select>
          </div>
          <div class="btns btn1" @click="changeSystem">
            {{ t('setting.save') }}
          </div>
        </div>
        <div class="divItem">
          <div class="title">{{ t('setting.languageType') }}</div>
          <div style="width: 305px; height: 40px; margin-bottom: 10px">
            <a-select
              ref="select"
              v-model:value="selectValue"
              style="width: 305px"
              size="large"
            >
              <a-select-option value="zh_CN">Chinese 中文</a-select-option>
              <a-select-option value="en_US">English 英语</a-select-option>
            </a-select>
          </div>
          <div class="btns btn1" @click="saveClick">
            {{ t('setting.save') }}
          </div>
          <div class="btns btn2" @click="layout">
            {{ t('setting.quitLogin') }}
          </div>
        </div>
      </template>
      <template v-if="!typeFlag && !quitRef">
        <a-form
          :model="formState"
          :rules="rules"
          ref="formRef"
          style="margin-top: 46px"
        >
          <a-form-item label="" name="password" style="margin-bottom: 46px">
            <a-input
              :type="inputType[0] ? 'password' : 'text'"
              style="
                padding-left: 30px;
                border-radius: 10px;
                position: relative;
              "
              v-model:value="formState.password"
              :placeholder="t('paswordSet.verifyOldMsg')"
              size="large"
            >
            </a-input>
            <KeyOutlined class="inputIcon" style="" />
            <div class="eyeBox">
              <EyeInvisibleOutlined v-if="inputType[0]" @click="typeClick(0)" />
              <EyeOutlined v-else @click="typeClick(0)" />
            </div>
            <div class="mesInput">{{ t('paswordSet.oldInputMsg') }}</div>
          </a-form-item>
          <a-form-item label="" name="newPassword">
            <a-input
              :type="inputType[1] ? 'password' : 'text'"
              style="
                padding-left: 30px;
                border-radius: 10px;
                position: relative;
              "
              v-model:value="formState.newPassword"
              :placeholder="t('paswordSet.verifyOldMsg')"
              size="large"
            >
            </a-input>
            <KeyOutlined class="inputIcon inputIconSenc" style="" />
            <div class="eyeBox eyeBoxSenc">
              <EyeInvisibleOutlined v-if="inputType[1]" @click="typeClick(1)" />
              <EyeOutlined v-else @click="typeClick(1)" />
            </div>
            <div class="mesInput">{{ t('paswordSet.newInputMsg') }}</div>
          </a-form-item>
        </a-form>
        <div class="btnBoxs">
          <div class="btns leftBtn" @click="cancel">{{ t('cancel') }}</div>
          <div class="btns rightBtn" @click="confimClick">
            {{ t('confirm') }}
          </div>
        </div>
      </template>
      <template v-if="quitRef">
        <div class="quitMess">{{ t('setting.quitMsg') }}</div>
        <div class="btnBoxs quitBtn">
          <div class="btns leftBtn" @click="cancleQuit">{{ t('cancel') }}</div>
          <div class="btns rightBtn" @click="confimClickQuit">
            {{ t('confirm') }}
          </div>
        </div>
      </template>
    </a-drawer>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { changePassword } from '@/api/login';
import defaultSettings from '@/settings';
import {
  CloseOutlined,
  KeyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue';
export default {
  components: {
    CloseOutlined,
    KeyOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
  },
  setup() {
    let { t, locale } = useI18n();
    const inputType = ref([true, true]); //控制密码的type（text｜password）
    const formRef = ref(); //form表单绑定
    const visible = ref(false); //控制抽屉显示
    const typeFlag = ref(true); //控制右边抽屉显示内容
    const quitRef = ref(false);
    const router = useRouter();
    const store = useStore();
    const selectValue = ref('zh_CN'); //select语言绑定
    const formState = reactive({
      password: '',
      newPassword: '',
    });
    const getAction = () => {
      const payload = localStorage.getItem('languageSet') || 'zh_CN';
      if (payload) {
        selectValue.value = payload;
      }
    };
    getAction();
    //初始化
    const action = () => {
      visible.value = true;
    };
    //点击关闭事件
    const closeClick = () => {
      typeFlag.value = true;
      quitRef.value = false;
      visible.value = false;
    };
    //点击关闭遮罩层
    const closeDiv = () => {
      typeFlag.value = true;
      visible.value = false;
      quitRef.value = false;
    };
    //点击保存
    const saveClick = () => {
      locale.value = selectValue.value;
      localStorage.setItem('languageSet', selectValue.value);
    };
    //修改密码
    const updatePassword = () => {
      typeFlag.value = false;
    };
    const layout = () => {
      quitRef.value = true;
    };
    //验证确认密码回调方法
    const handleConfirmPass = (rule, value, callback) => {
      if (formState.password !== value) {
        callback(`${t('paswordSet.oldNewOvifyMsg')}`);
      }
      callback();
    };
    const rules = {
      password: {
        required: true,
        message: t('paswordSet.verifyOldMsg'),
        trigger: 'blur',
      },
      newPassword: [
        {
          required: true,
          message: t('paswordSet.verifyNewMsg'),
          trigger: 'blur',
        },
        { validator: handleConfirmPass },
      ],
    };
    //点击修改密码
    const confimClick = () => {
      formRef.value.validate().then(() => {
        changePassword({ password: formState.password }).then((res) => {
          if (res.code === 200) {
            message.success('密码修改成功，请重新登录！');
            // data.modalType = 0;
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('userName');
            store.commit('setMyResource', []);
            router.push('/login');
          } else {
            message.error(`密码修改失败：${res.message}`);
          }
        });
      });
    };
    const typeClick = (index) => {
      inputType.value[index] = !inputType.value[index];
    };
    //点击取消 清空密码输入框内容
    const cancel = () => {
      typeFlag.value = true;
      formState.password = '';
      formState.newPassword = '';
    };
    const cancleQuit = () => {
      quitRef.value = false;
    };
    const confimClickQuit = () => {
      // window.localStorage.removeItem('token');
      // window.localStorage.removeItem('userName');
      window.localStorage.removeItem('userType');
      window.localStorage.removeItem('userInfo');
      store.commit('setToken', '');
      store.commit('setUserName', '');
      store.commit('setMyResource', []);
      store.commit('setUserType', '');
      router.push('/login');
    };
    const userType = ref(store.getters.userType)
    const changeSystem = async () => {
      if (store.getters.userType == userType.value) {
        closeClick()
        return
      }
      await store.dispatch('setUserType', userType.value);
      store.dispatch('routerRedirect');
      closeClick()
    };
    const userInfo = computed(() => store.getters.userInfo || {})
    const { platformNames } = defaultSettings;
    return {
      t,
      formRef,
      inputType,
      locale,
      visible,
      typeFlag,
      quitRef,
      selectValue,
      formState,
      rules,
      closeClick,
      closeDiv,
      saveClick,
      updatePassword,
      layout,
      confimClick,
      typeClick,
      cancel,
      cancleQuit,
      confimClickQuit,
      action,
      userType,
      changeSystem,
      platformNames,
      userInfo,
    };
  },
};
</script>

<style>
.ant-drawer-content-wrapper {
  width: 370px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}
</style>
<style lang="less">
::v-deep(.ant-form-item-control-input-content) {
  height: 32px !important;
}
.mesInput {
  position: absolute;
  top: -20px;
  left: 2px;
  color: #131011;
  font-size: 12px;
  line-height: 18px;
}
.quitMess {
  color: #050505;
  font-size: 16px;
  line-height: 22px;
  margin: 24px 0;
}
.quitBtn {
  margin-top: 0 !important;
}

.inputIcon {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 18px;
  margin-right: 6px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.inputIconSenc {
  // top: 30% !important;
}

.eyeBox {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  font-size: 14px;
}
.eyeBoxSenc {
  // top: 30%;
}
.inputMes {
  color: #131011;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
}
.btnBoxs {
  margin-top: 32px;
  width: 305px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  .btns {
    width: 144px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }
  .leftBtn {
    background: #d2d2d2;
    color: #131011;
  }
  .rightBtn {
    background: #ff6700;
    color: #fff;
  }
}
.navDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tit {
    color: #131011;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }
  .colse {
    font-size: 14px;
    color: #131011;
    cursor: pointer;
  }
}
.btns {
  width: 305px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}
.btn1 {
  background: #ff6700;
}
.btn2 {
  background: #131011;
  margin-top: 40px;
}
.divItem {
  margin-top: 30px;
  .title {
    color: #131011;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
  }
}
#components-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  #admin-home-content {
    position: relative;
    overflow-y: scroll;
    //overflow-x:scroll;
    //padding-top: 20px;
    //padding-bottom: 20px;
    //padding: 20px;
  }

  .trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    font-size: 20px;
    color: white;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /*background: #001529;*/
    z-index: 1;

    img {
      height: 16px;
    }
  }
}

.ant-layout {
  //background-color: #F7F7FF;
  background-color: #ffffff;
}
</style>
